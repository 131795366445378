import React, { useState } from 'react';
import axios from 'axios';

function GeneratePlan() {
  const [formData, setFormData] = useState({
    businessName: '',
    industry: '',
    description: ''
  });
  const [plan, setPlan] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('YOUR_API_GATEWAY_ENDPOINT/collect-email', formData);
      setPlan(response.data.plan);
    } catch (error) {
      console.error('Error generating plan', error);
    }
  };

  return (
    <div>
      <h1>Generate Your Business Plan</h1>
      <form onSubmit={handleSubmit}>
        <label>Business Name:</label>
        <input type="text" name="businessName" value={formData.businessName} onChange={handleChange} required />
        <label>Industry:</label>
        <input type="text" name="industry" value={formData.industry} onChange={handleChange} required />
        <label>Description:</label>
        <textarea name="description" value={formData.description} onChange={handleChange} required></textarea>
        <button type="submit">Generate Plan</button>
      </form>
      {plan && <div><h2>Your Generated Plan:</h2><p>{plan}</p></div>}
    </div>
  );
}

export default GeneratePlan;
