import React from 'react';

function Home() {
  return (
    <div>
      <h1>Welcome to Alternative Reality Group's Business Planning Service</h1>
      <p>Create professional business plans with our proprietary cutting-edge artificial intelligence technology.</p>
    </div>
  );
}

export default Home;
