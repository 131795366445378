import React from 'react';

function Contact() {
  return (
    <div>
      <h1>Sign-Up Now</h1>
      <p>Register your interest, and be the first to be contacted once we launch!</p>
      <form>
        <label>First Name:</label>
        <input type="text" name="f_name" required />
        <label>Last Name:</label>
        <input type="text" name="l_name" required />
        <label>Email:</label>
        <input type="email" name="email" required />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default Contact;
