import React from 'react';

function About() {
  return (
    <div>
      <h1>About Us</h1>
      <p>We provide AI-driven solutions to help you create comprehensive business plans.</p>
      <h2>Why use Alternative Reality Group?</h2>
      <p>We make it fast and easy to go from idea to a fully formed business plan that is ready to be used.</p>
      <p>Our solution allows you to take advantage of our proprietary virtual business consultants who can help you through every step of business plan creation.</p>
    </div>
  );
}

export default About;
